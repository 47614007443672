import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite'
import {
    Button, Input, Textarea, Flex, Alert, AlertIcon, AlertTitle, FormControl, FormLabel, Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'actions/actions';

function ContactForm() {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(state=>state.data)

    const validationSchema = Yup.object({
        fullName: Yup.string().required('Full name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        message: Yup.string().required('Message is required'),
    });

    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(fetchData());
      }, [dispatch]); 

    console.log(data)
        
    const initialValues = {
        fullName: '',
        email: '',
        message: '',
    };

    const handleSubmit = (values, { resetForm }) => {
        // Handle form submission here, e.g., send data to the server
        console.log('Form data:', values);
      
        // Clear the form fields after submission
        resetForm();
      
        // Set the success message
        setIsSubmitted(true);
      
        // Clear the success message after a few seconds (optional)
        setTimeout(() => {
          setIsSubmitted(false);
        }, 5000); // Adjust the timeout as needed
      };      

    return (
        <>
        <Flex className={css(styles.main)}>
            <Text fontSize={24} mb={10} fontWeight='bold' textAlign='center'>
                Please fill this form to send your queries and our team will get connected with you shortly.
            </Text>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form className={css(styles.parent)}>
                    <Flex className={css(styles.form)}>
                        <Flex className={css(styles.formControl)}>
                            <FormControl>
                                <FormLabel
                                htmlFor="fullName" fontWeight='bold' fontSize={20}>
                                    Full Name
                                </FormLabel>
                                <Field
                                as={Input}
                                type="text"
                                id="fullName"
                                name="fullName"
                                placeholder="Please enter your full name" />
                                <ErrorMessage name="fullName" component="div" className={css(styles.error)} />
                            </FormControl>
                        </Flex>
                        <Flex className={css(styles.formControl)}>
                            <FormControl>
                                <FormLabel htmlFor="email" fontWeight='bold' fontSize={20}>Email</FormLabel>
                                <Field
                                as={Input}
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Please enter your email"
                                />
                                <ErrorMessage name="email" component="div" className={css(styles.error)} />
                            </FormControl>
                        </Flex>

                        <Flex className={css(styles.formControl)}>
                            <FormControl>
                                <FormLabel htmlFor="message" fontWeight='bold' fontSize={20}>Message</FormLabel>
                                <Field
                                as={Textarea}
                                type="message"
                                id="message"
                                name="message"
                                placeholder="Please enter your message here"
                                />
                                <ErrorMessage name="message" component="div" className={css(styles.error)} />
                            </FormControl>
                        </Flex>
                        <Button
                            mt={4}
                            color="white"
                            backgroundColor="black"
                            _hover={{ backgroundColor: '#36454F' }}
                            type="submit"
                        >
                            Submit
                        </Button>
                        {isSubmitted && (
                        <Alert status="success" mt={4}>
                            <AlertIcon />
                            <AlertTitle mr={2}>Form submitted successfully!</AlertTitle>
                        </Alert>
                        )}
                    </Flex>
                </Form>
            </Formik>
        </Flex>
        </>
    );
    }

const styles = StyleSheet.create({
    main: {
        justifyContent: 'center',
        marginTop: 100,
        alignItems: 'center',
        // width: '50%',
        flexDirection: 'column',
        marginBottom: 25
    },
    parent: {
        justifyContent: 'center',
        alignItems: 'center',
        // marginTop: 75,
        width: '75%'
    },
    form: {
        // width: '40%',
        alignItems: 'center',
        padding: 25,
        borderWidth: 3,
        borderColor: 'gray',
        borderRadius: '1%',
        flexDirection: 'column',
    },
    formControl: {
        marginBottom: '1rem',
        flexDirection: 'column'
    },
    error: {
        color: 'red',
    },
    line: {
        width: 1,
        backgroundColor: 'black', 
        height: '100vh',
        marginLeft: 10
    }
});

export default ContactForm