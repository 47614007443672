import React, {useState, useEffect} from 'react';
import {
    Tabs, TabList, TabPanels, Tab, TabPanel, Image, Box, Text, Flex, SimpleGrid, useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react'
import { StyleSheet, css } from 'aphrodite';
// import { seminarDetails } from './SeminarDetails';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'actions/actions';

function TTESeminar() {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedSeminar, setSelectedSeminar] = useState(null);
    const dispatch = useDispatch();
    const data = useSelector(state=>state.data.data.seminar)

    const isMobile = useSelector(state=> state.windowSize.isMobile);

    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(fetchData());
      }, [dispatch]); 

    console.log('TTE:', data)

    const handleSeminarClick = (seminarTitle) => {
        const foundSeminar = data?.find(seminar => seminar.title === seminarTitle);
        if (foundSeminar) {
            console.log("selectedSeminar updated:", foundSeminar);
          setSelectedSeminar(foundSeminar);
          onOpen();
        }
    };

  return (
    <>
    {data?(
        <Tabs isFitted variant='enclosed'>
            <TabList mb='1em'>
                <Tab className={css(styles.tabParent)} _selected={{bg: '#e2e1dc'}}>
                    UPCOMING
                </Tab>
                <Tab className={css(styles.tabParent)} _selected={{bg: '#e2e1dc'}}>
                    PAST
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                <SimpleGrid columns={isMobile ? 1 : 3} spacing={10} justifyContent='center'>
                        {data?.filter((seminar) => seminar.category === 'upcoming' && seminar.type === 'TTE')
                        .map((content, index) => (
                            <Box className={css(styles.card)} onClick={() => handleSeminarClick(content.title)} key={index}>
                                {/* <Flex justifyContent={'center'}>
                                    <Image src={content.image} boxSize={'sm'} />
                                </Flex> */}
                                <Text className={css(styles.heading)} fontSize={20}>
                                    {content.title}
                                </Text>
                                <Text className={css(styles.subtitle)}>
                                    {content.date}
                                </Text>
                                <Flex justifyContent='center'>
                                    <hr style={{width: '50%', borderWidth: '2px'}} />
                                </Flex>
                                <Text className={css(styles.subtitle)}>
                                    {content.address}
                                </Text>
                            </Box>
                        ))}
                    </SimpleGrid>
                    {data?.filter((seminar) => seminar.category === 'upcoming' && seminar.type === 'TTE').length === 0 && (
                        <Text textAlign='center' width='100%' fontWeight='bold'>
                            Exciting Seminars Coming Soon, Stay Tuned!
                        </Text>
                    )}
                </TabPanel>
                <TabPanel>
                <SimpleGrid columns={isMobile ? 1 : 3} spacing={10} justifyContent='center'>
                        {data?.filter((seminar) => seminar.category === 'past' && seminar.type === 'TTE')
                        .map((content, index) => (
                            <Box className={css(styles.card)} onClick={() => handleSeminarClick(content.title)} key={index}>
                                {/* <Flex justifyContent={'center'}>
                                    <Image src={content.image} boxSize={'sm'} />
                                </Flex> */}
                                <Text className={css(styles.heading)} fontSize={20}>
                                    {content.title}
                                </Text>
                                <Text className={css(styles.subtitle)}>
                                    {content.date}
                                </Text>
                                <Flex justifyContent='center'>
                                    <hr style={{width: '50%', borderWidth: '2px'}} />
                                </Flex>
                                <Text className={css(styles.subtitle)}>
                                    {content.address}
                                </Text>
                            </Box>
                        ))}
                    </SimpleGrid>
                    {data?.filter((seminar) => seminar.category === 'past' && seminar.type === 'TTE').length === 0 && (
                        <Text textAlign='center' width='100%' fontWeight='bold'>
                            Exciting Seminars Coming Soon, Stay Tuned!
                        </Text>
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    ):(
        <Flex>
            Loading...
        </Flex>
    )}

    <Modal isOpen={isOpen} onClose={onClose} isCentered size='3xl'>
    <ModalOverlay />
    <ModalContent>
        {selectedSeminar && (
            <>
            <ModalHeader>About our host</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Image src={selectedSeminar.imageLink} alt={selectedSeminar.title} width='100%' />
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
            </>
        )}
    </ModalContent>
    </Modal>
    </>
  )
};

const styles = StyleSheet.create({
    tabParent: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#DC242C'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18',
        textAlign: 'center',
        marginTop: 5
    },
    subtitle: {
        fontSize: '20',
        textAlign: 'center',
        marginTop: 5,
        fontWeight: 'bold'
    },
    card: {
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: 'gray',
        justifyContent: 'center',
        textAlign: 'center',
        ':hover': {
          cursor: 'pointer'  
        },
        position: 'relative'
    },
    dateCircle: {
        position: 'absolute',
        bottom: 75,
        left: '50%', // Adjust as needed for positioning
        transform: 'translateX(-50%)',
        width: '10%',
        height: '10%',
        borderRadius: '50%',
        backgroundColor: 'black', // Change color as needed
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1, // Ensure the circle is above the image
      },
      dateText: {
        color: 'white',
        fontSize: '16',
        // fontWeight: 'bold',
        // Adjust text styles as needed
      },
})

export default TTESeminar