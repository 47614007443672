import React from 'react'
import { useSelector } from "react-redux";
import NewsCarousel from 'components/News/NewsCarousal';
import MobileNewsCarousel from 'components/News/MobileNewsCarousal';
import NewsCardsFr from 'components/News/NewsCardsFr';
import MobileNavbarFr from 'components/MobileNavbarFr';
import MobileFooterFr from 'components/MobileFooterFr';
import FooterFr from 'components/FooterFr';
import NavbarFr from 'components/NavbarFr';

function NewsFr() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbarFr />
      <MobileNewsCarousel />
      <NewsCardsFr />
      <MobileFooterFr />
      </>
    ) : (
      <>
      <NavbarFr />
      <NewsCarousel />
      <NewsCardsFr />
      <FooterFr />
      </>
    )}
    </>
  )
}

export default NewsFr