import React, {useEffect} from 'react';
import {
    Image, Box, Text, Flex, SimpleGrid, useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button
} from '@chakra-ui/react'
import { StyleSheet, css } from 'aphrodite';
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from 'actions/actions';

function NewsCardsFr() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const isMobile = useSelector(state => state.windowSize.isMobile);
    const dispatch = useDispatch();
    const data = useSelector(state=>state.data.data.newsFr)

    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(fetchData());
      }, [dispatch]); 

    console.log('NewsFr:', data)
    //just map the data below in the return statement

  return (
    <>
    <SimpleGrid columns={isMobile ? 1 : 3} spacing={10}
    className={css(styles.gridStyle)}
    mt={isMobile ? 3 : 5}
    >
    <Box className={css(styles.card)} onClick={onOpen}>
            {/* <Flex justifyContent={'center'}>
                <Image src='seminar/Seminar1Fr.png' boxSize={'sm'} />
            </Flex> */}
            <Text className={css(styles.heading)}>
                COMMUNICATIONS ET RÉSEAUX DANS LES MÉGA-CONSTELLATIONS EN ORBITE BASSE(LEO)
            </Text>
            <Text className={css(styles.subtitle)}>
                10h à 11h, Novembre 24th 2023
            </Text>
            <Flex justifyContent='center'>
                <hr style={{width: '50%', borderWidth: '2px'}} />
            </Flex>
            <Text className={css(styles.subtitle)}>
                535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
            </Text>
        </Box>

        <Box className={css(styles.card)} onClick={onOpen}>
            {/* <Flex justifyContent={'center'}>
                <Image src='seminar/Seminar1Fr.png' boxSize={'sm'} />
            </Flex> */}
            <Text className={css(styles.heading)}>
                COMMUNICATIONS ET RÉSEAUX DANS LES MÉGA-CONSTELLATIONS EN ORBITE BASSE(LEO)
            </Text>
            <Text className={css(styles.subtitle)}>
            10h à 11h, Novembre 24th 2023
            </Text>
            <Flex justifyContent='center'>
                <hr style={{width: '50%', borderWidth: '2px'}} />
            </Flex>
            <Text className={css(styles.subtitle)}>
                535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
            </Text>
        </Box>

        <Box className={css(styles.card)} onClick={onOpen}>
            {/* <Flex justifyContent={'center'}>
                <Image src='seminar/Seminar1Fr.png' boxSize={'sm'} />
            </Flex> */}
            {/* <Flex className={css(styles.dateCircle)}>
                <Text className={css(styles.dateText)}>Nov 24, 2023</Text>
            </Flex> */}
            <Text className={css(styles.heading)}>
                COMMUNICATIONS ET RÉSEAUX DANS LES MÉGA-CONSTELLATIONS EN ORBITE BASSE(LEO)
            </Text>
            <Text className={css(styles.subtitle)}>
            10h à 11h, Novembre 24th 2023
            </Text>
            <Flex justifyContent='center'>
                <hr style={{width: '50%', borderWidth: '2px'}} />
            </Flex>
            <Text className={css(styles.subtitle)}>
                535 LEGGET DRIVE, SUITE 200, KANATA, ON K2K 3B8
            </Text>
        </Box>
    </SimpleGrid>

    <Modal isOpen={isOpen} onClose={onClose} isCentered size='3xl'>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>À propos de notre hôte</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
        <Image src='seminar/Seminar1DetailsFr.png' alt='Gunes Karabulut-Kurt' width='100%' />
    </ModalBody>

    <ModalFooter>
        <Button colorScheme='red' mr={3} onClick={onClose}>
            Close
        </Button>
    </ModalFooter>
    </ModalContent>
    </Modal>
    </>
  )
};

const styles = StyleSheet.create({
    tabParent: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#DC242C'
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18',
        textAlign: 'center',
        marginTop: 5
    },
    subtitle: {
        fontSize: '20',
        textAlign: 'center',
        marginTop: 5,
        fontWeight: 'bold'
    },
    card: {
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: 'gray',
        justifyContent: 'center',
        textAlign: 'center',
        ':hover': {
          cursor: 'pointer'  
        },
        position: 'relative'
    },
    gridStyle: {
        justifyContent: 'center',
        marginBottom: 10,
        marginLeft: 10,
        marginRight: 10
    }
})

export default NewsCardsFr