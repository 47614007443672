import {
  Flex,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Text,
} from "@chakra-ui/react";
import React, {useState, useEffect} from "react";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { publicationDetails } from "./PublicationDetails";
import { fetchData } from 'actions/actions';

function PublicationsListFr() {

  const dispatch = useDispatch();

  const isMobile = useSelector(state => state.windowSize.isMobile);
  const data = useSelector(state=>state.data.data.publications)
  const journals = data?.filter(publication => publication.type === 'journal');
  const conference = data?.filter(publication => publication.type === 'conference');

  const [currentPageJr, setCurrentPageJr] = useState(1);
  const [currentPageCn, setCurrentPageCn] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    // Dispatch the fetchData action when the component mounts
    dispatch(fetchData());
  }, [dispatch]); 

  console.log('PublicationsFr', data)

  // Calculate indexes for pagination
  const indexOfLastItem = currentPageJr * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentJournals = journals.slice(indexOfFirstItem, indexOfLastItem);
  const currentConference = conference.slice(indexOfFirstItem, indexOfLastItem);

  const totalPagesJr = Math.ceil(journals.length / itemsPerPage);
  const totalPagesCn = Math.ceil(journals.length / itemsPerPage);

  // Journal Pagination: 
  const handleNextPageJr = () => {
    if (currentPageJr < totalPagesJr) {
      setCurrentPageJr(currentPageJr + 1);
    }
  };

  const handlePrevPageJr = () => {
    if (currentPageJr > 1) {
      setCurrentPageJr(currentPageJr - 1);
    }
  };

  // Conference Pagination
  const handleNextPageCn = () => {
    if (currentPageCn < totalPagesCn) {
      setCurrentPageCn(currentPageCn + 1);
    }
  };

  const handlePrevPageCn = () => {
    if (currentPageCn > 1) {
      setCurrentPageCn(currentPageCn - 1);
    }
  };

  return (
      <Box className={css(styles.head)}>

        {/* Journals */}
        <Flex className={css(styles.container)}>
          ARTICLES DE JOURNAUX
        </Flex>
        <Flex className={css(styles.accordion)}>
          <Accordion allowMultiple width='100%' defaultIndex={[0]}>
            {currentJournals.map((publication, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box
                    as="span"
                    flex='1'
                    textAlign='left'
                    fontSize={20}
                    fontWeight='bold'
                  >
                    "{publication.title}"
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                  <Box>
                  <b>{`${publication.writers}`}</b>, {`"${publication.title}," in ${publication.conference}, ${publication.publicationDate}.
                  DOI: ${publication.doi}`}
                  <Flex mt={3}>
                      {isMobile ? '' : 'Paper link:'}
                      <Flex className={css(styles.link)}>
                      <Link to={publication.link}>
                          {`${publication.link}`}
                      </Link>
                      </Flex>
                  </Flex>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>

        {/* Pagination Controls */}
        {journals.length > itemsPerPage && (
            <Flex justifyContent='center' mt={4} alignItems='center'>
              <Button onClick={handlePrevPageJr} disabled={currentPageJr === 1}>
                Précédente
              </Button>
              <Text mx={4}>
                Page {currentPageJr} sur {totalPagesJr}
              </Text>
              <Button onClick={handleNextPageJr} disabled={currentPageJr === totalPagesJr}>
                Suivante
              </Button>
            </Flex>
          )}

        {/* Conference */}
        <Flex className={css(styles.container)}>
          ARTICLES DANS DES ACTES DE CONFÉRENCE
        </Flex>
        <Flex className={css(styles.accordion)}>
          <Accordion allowMultiple width='100%' defaultIndex={[0]}>
            {currentConference.map((publication, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Box
                    as="span"
                    flex='1'
                    textAlign='left'
                    fontSize={20}
                    fontWeight='bold'
                  >
                    "{publication.title}"
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                  <Box>
                  <b>{`${publication.writers}`}</b>, {`"${publication.title}," in ${publication.conference}, ${publication.publicationDate}.
                  DOI: ${publication.doi}`}
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Flex>

        {/* Pagination Controls */}
        {conference.length > itemsPerPage && (
            <Flex justifyContent='center' mt={4} alignItems='center'>
              <Button onClick={handlePrevPageCn} disabled={currentPageCn === 1}>
                Précédente
              </Button>
              <Text mx={4}>
                Page {currentPageCn} sur {totalPagesCn}
              </Text>
              <Button onClick={handleNextPageCn} disabled={currentPageCn === totalPagesCn}>
                Suivante
              </Button>
            </Flex>
          )}
      </Box>
    );
  }

const styles = StyleSheet.create({
  head: {
      maxWidth: '100vw',
      marginTop: 100
  },
  container: {
      maxWidth: '100%',
      justifyContent: 'center',
      fontSize: 24,
      margin: '1%',
      fontWeight: 'bolder',
      textShadow: '1px 1px gray',
      textAlign: 'center'
  },
  accordion: {
      maxWidth: '100vw',
      textAlign: 'center',
      paddingBottom: 0,
      margin: '1%'
  },
  link:{
      fontWeight: 'bold',
      color: '#315369',
      ':hover': {
          textDecoration: 'underline'
      }
  }
});

export default PublicationsListFr