import FooterFr from 'components/FooterFr'
import MobileFooterFr from 'components/MobileFooterFr'
import MobileNavbarFr from 'components/MobileNavbarFr'
import NavbarFr from 'components/NavbarFr'
import DescriptionFr from 'components/Seminar/DescriptionFr'
import TechnicalFr from 'components/Seminar/TechnicalFr'
// import WorkshopVidsFr from 'components/Seminar/WorkshopVidsFr'
import React from 'react';
import { useSelector } from "react-redux";

function SeminarsFr() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbarFr />
      <DescriptionFr />
      <TechnicalFr />
      {/* <WorkshopVidsFr /> */}
      <MobileFooterFr />
      </>
    ) : (
      <>
      <NavbarFr />
      <DescriptionFr />
      <TechnicalFr />
      {/* <WorkshopVidsFr /> */}
      <FooterFr />
      </>
    )}
    </>
  )
}

export default SeminarsFr