import React from 'react';
import { Box } from '@chakra-ui/react';
import { css, StyleSheet } from 'aphrodite';
import { useSelector } from 'react-redux';

function Description() {
  const isMobile = useSelector(state=> state.windowSize.isMobile)

  return (
    <>
    {/* <Flex className={css(styles.container)}>
        DESCRIPTION
    </Flex> */}
    <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
    The TRAVERSAL seminars will cover a wide array of topics, including cutting-edge developments in various fields of research,
    technology transfer, and entrepreneurship. Featuring distinguished speakers from around the globe, the seminars offer a
    unique opportunity for knowledge-sharing, collaboration, and networking.
    </Box>
    <hr />
    </>
  )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
        marginTop: 75
    },
    textbox: {
      margin: '2%',
      marginTop: 100,
      marginBottom: 10,
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bolder',

  },
})

export default Description