import React from 'react'
import { useSelector } from 'react-redux'
import {
    Flex, Box, SimpleGrid, Image,
    // Text
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function IndustryPartners() {

    const isMobile = useSelector(state=> state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
        <>
        <SimpleGrid columns={2} spacing={5} textAlign="center" marginTop={5} marginBottom={5}>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        boxSize='50%'
                        src="irdeto-logo.png"
                        alt='Irdeto Logo'
                    />
                </Flex>
                {/* <Text>Irdeto</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        boxSize='75%'
                        src='wedge-logo.png'
                        alt='Wedge Logo'
                    />
                </Flex>
                {/* <Text>Wedge Networks</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center' marginTop={10} marginBottom={5}>
                    <Image
                        objectFit='contain'
                        boxSize='75%'
                        src='quanser_logo.jpeg'
                        alt='Quanser Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center' marginTop={10} marginBottom={5}>
                    <Image
                        objectFit='contain'
                        boxSize='75%'
                        src='wesley.png'
                        alt='Wesley Clover Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
            <Flex justifyContent='center'>
                <Image
                    objectFit='cover'
                    // boxSize='75%'
                    src='ciena_logo.jpg'
                    alt='Ciena Logo'
                />
            </Flex>
            {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='cover'
                        boxSize='75%'
                        src='aurrigo_logo.png'
                        alt='Aurrigo Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            </SimpleGrid>
        {/* </Box> */}
        </>
    ) : (
        <>
        <SimpleGrid spacing={10} columns={3} textAlign="center" alignItems='center'>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        boxSize='sm'
                        src="irdeto-logo.png"
                        alt='Irdeto Logo'
                    />
                </Flex>
                {/* <Text>Irdeto</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        boxSize='sm'
                        src='wedge-logo.png'
                        alt='Wedge Logo'
                    />
                </Flex>
                {/* <Text>Wedge Networks</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        boxSize='sm'
                        src='quanser_logo.jpeg'
                        alt='Quanser Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='contain'
                        src='wesley.png'
                        alt='Wesley Clover Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='cover'
                        // boxSize='80%'
                        src='ciena_logo.jpg'
                        alt='Ciena Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            <Box className={css(styles.textbox)}>
                <Flex justifyContent='center'>
                    <Image
                        objectFit='cover'
                        boxSize='75%'
                        src='aurrigo_logo.png'
                        alt='Aurrigo Logo'
                    />
                </Flex>
                {/* <Text>Quanser</Text> */}
            </Box>
            </SimpleGrid>
        </>
    )}
    </>
  )
}

const styles = StyleSheet.create({
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18,
        justifyContent: 'center',
        alignContent: 'center'
    },
})

export default IndustryPartners