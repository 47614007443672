import React from 'react';
import { Flex, Box, SimpleGrid, Image } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';

function FooterFr() {
  return (
    <>
    <Flex className={css(styles.parent)}>
    <Flex className={css(styles.container1)}>
            <Box justifyContent="center">
                <Image src="NSERC_RGB.png" alt="nserc logo" boxSize="20%" fit="fit" />
            </Box>
            <Box textAlign="left" fontWeight="bold">
                Nous reconnaissons le soutien du Conseil de recherches en sciences naturelles et en génie du Canada (CRSNG).
            </Box>
        </Flex>
        <Flex className={css(styles.container2)}>
            <SimpleGrid columns={2} spacing={5} textAlign='center'>
                <Box className={css(styles.links)}>
                    <Link to='/fr'>
                        ACCUEIL
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/about-us-fr'>
                        L'EQUIPE
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/program-fr'>
                        PROGRAMME
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/seminars-fr'>
                        SÉMINAIRES 
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/publication-fr'>
                        PUBLICATIONS
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/news-fr'>
                        NOUVELLES
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/contact-us-fr'>
                        CONTACTEZ-NOUS
                    </Link>
                </Box>
            </SimpleGrid>
        </Flex>
    </Flex>
    </>
  )
};

const styles = StyleSheet.create({
    parent: {
        backgroundColor: '#e2e1dc',
        paddingTop: '1%',
        paddingBottom: '1%',
        // position: 'fixed',
        bottom: 0,
        width: '100%'
    },
    container1: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block'
    },
    container2: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block'
    },
    links: {
        fontWeight: 'bold',
        ":hover": {
            textDecoration: 'underline',
            color: '#DC242C'
        }
    }
})

export default FooterFr