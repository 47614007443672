import React from "react";
import {
    Flex,
    Box,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function Internship () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                INTERNSHIPS
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            Trainees will have the opportunity to embark on an industrial internship program that
            delves into various facets of our program in collaboration with esteemed industry partners.
            These internships include:
            </Box>
            <Flex className={css(styles.accordion)}>
                <Accordion width='100%' allowToggle allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                                Trustworthy and Responsible AI Internships
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Delve into the ethical and responsible dimensions of AI during your internship.
                        Gain practical experience in ensuring that AI systems are built with trustworthiness
                        and ethical considerations in mind.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            Cybersecurity and Cyber-Physical Training 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Join our industry partners for specialized training in cybersecurity and cyber-physical aspects.
                        Develop expertise in safeguarding digital and physical systems from threats and vulnerabilities.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            Communication, Sensing, and Human Factors Training
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Enhance your skills in communication, sensor technology, and human factors by participating in training
                        sessions offered by our industry partners. Explore the essential components of effective communication
                        and human-centred design principles.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: 0,
        marginBottom: 0,
        // textAlign: 'left',
        fontSize: 18,
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})

export default Internship