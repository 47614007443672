import {
    Flex,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function Training () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                TRAINING
            </Flex>
            <Flex className={css(styles.accordion)}>
                <Accordion Accordion allowToggle width='100%' allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                                AV-UAV Systems Survey & Tutorial Workshops
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            <b>The AV-UAV Systems Survey & Tutorial Workshops</b> are a vital component of the proposed program.
                            These workshops are designed to provide trainees with comprehensive training in producing
                            surveys and tutorials on state-of-the-art topics within the field of Autonomous Vehicle-Uncrewed
                            Aerial Vehicle (AV-UAV) systems. The goal is to equip trainees with the skills necessary to
                            explore and understand the latest developments, challenges, and opportunities in their respective
                            research areas.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                            New graduate-level course stream 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        <b>The CREATE TRAVERSAL program</b> is set to introduce a range of cutting-edge graduate-level courses.
                        These courses, offered both on-campus and through distance learning, cover critical areas in
                        autonomous systems.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                            Professional Skills & Training
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        <b>The Annual TRAVERSAL Workshop</b> is a key event where trainees engage with industry and academic
                        experts through talks, panels, and research showcases. This gathering fosters networking opportunities
                        and explores themes aligned with the program's scope.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
        marginTop: 100
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})

export default Training