import React from 'react';
import { Box, Flex } from '@chakra-ui/react'
import { StyleSheet, css } from 'aphrodite';
import { useSelector } from 'react-redux';
import TEMSeminarFr from './TEMSeminarFr';
import TTESeminarFr from './TTESeminarFr';

function TechnicalFr() {

  const isMobile = useSelector(state=> state.windowSize.isMobile)

  return (
    <Box className={css(styles.head)}>
        <Flex className={css(styles.container)} textAlign={isMobile ? 'center' : 'left'}>
        SÉRIE DE SÉMINAIRES DE MENTORAT TECHNIQUE-ÉTHIQUE
        </Flex>
        <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
        La Série de Séminaires de Mentorat Technique-Éthique, abrégée sous le nom de TEM, est un élément essentiel du programme TRAVERSAL.
        Cette série offre aux stagiaires des informations précieuses sur les dernières avancées techniques dans le domaine des véhicules
        autonomes (VA) et des véhicules aériens sans équipage (UAV). De plus, TEM aborde les dimensions légales et éthiques
        associées aux services basés sur les VA et les UAV. Elle comprend un minimum de huit séminaires par an, garantissant
        que les stagiaires acquièrent des connaissances complètes et des orientations à la fois techniques et éthiques dans leur domaine.
        Grâce à TEM, les stagiaires acquièrent une compréhension globale du paysage complexe et en évolution des technologies des VA et des UAV.
        </Box>
        <TEMSeminarFr />
        <hr />
        <Flex className={css(styles.container)} textAlign={isMobile ? 'center' : 'left'}>
        ENTREPRENEURIAT EN TRANSFERT DE TECHNOLOGIE
        </Flex>
        <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
        L'aspect de l'Entrepreneuriat en Transfert de Technologie du programme TRAVERSAL se concentre sur l'équipement des stagiaires avec les
        connaissances et les compétences nécessaires pour la réussite de la préparation technologique et de la commercialisation.
        Ce segment propose des stratégies pour transformer les résultats de la recherche du programme en produits ou services commerciaux viables.
        Les stagiaires bénéficient du soutien en séminaire grâce aux bureaux de transfert de technologie et profitent de l'expertise d'anciens
        diplômés des accélérateurs de start-up. Pour garantir une formation complète, les stagiaires doivent assister à un minimum de quatre
        séminaires dans chaque filière par an. Cette emphase sur le transfert de technologie et l'entrepreneuriat permet aux stagiaires de naviguer
        efficacement dans le parcours de la recherche aux applications réelles, favorisant ainsi l'innovation et l'impact économique.
        </Box>
        <TTESeminarFr />
    </Box>
  )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: 0,
        marginBottom: 10,
        // textAlign: 'left',
        fontSize: 18,
    },
})

export default TechnicalFr