import Navbar from "components/Navbar"
import Description from "components/Seminar/Description"
import Technical from "components/Seminar/Technical"
import Footer from "components/Footer";
import { useSelector } from "react-redux";
import MobileNavbar from "components/MobileNavbar";
import MobileFooter from "components/MobileFooter";
// import WorkshopVids from "components/Seminar/WorkshopVids";


function Seminars () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return(
        <>
        {isMobile ? (
            <>
            <MobileNavbar />
            <Description />
            <Technical />
            {/* <WorkshopVids /> */}
            <MobileFooter />
            </>
        ) : (
            <>
            <Navbar />
            <Description />
            <Technical />
            {/* <WorkshopVids /> */}
            <Footer />
            </>
        )}
        </>
    )
}

export default Seminars