import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function InternationalExchanges () {
    const isMobile = useSelector(state => state.windowSize.isMobile);

    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                INTERNATIONAL EXCHANGES
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            <b>Masters and PhD trainees</b> enrolled in our program will have the exceptional
            opportunity to participate in an international exchange program, which will be
            awarded on a competitive basis. This initiative is designed to provide our
            trainees with a unique and enriching experience that extends beyond the
            boundaries of the trainee. 
            <p />
            <br />
            <b>The TRAVERSAL program</b> extends its reach globally through an International Exchange
            initiative. In addition to industry internships, trainees have the chance to engage
            in research projects and collaborations with partner institutions in the United States,
            Brazil, Germany, the United Kingdom, and France. This exchange program enriches their perspectives,
            fosters international research exposure, and promotes collaboration on cutting-edge projects.
            We also offer virtual internships to adapt to evolving travel conditions. All opportunities
            are provided on a competitive and equitable basis, overseen by institutional Equity, Diversity,
            and Inclusion (EDI) Advisors.
            </Box>
            <hr />
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        // textAlign: 'left',
        fontSize: 18
    },
})

export default InternationalExchanges 