import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function MissionVisionFr() {
  return (
    <>
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                MISSION
            </Flex>
            <Box className={css(styles.textbox)}>
            CREATE TRAVERSAL s'engage à doter les HQP (Hautes Qualifications Professionnelles) de compétences techniques et
            comportementales, à promouvoir la collaboration interdisciplinaire et à propulser le Canada au premier plan
            du leadership en matière de technologie autonome grâce aux véhicules autonomes (VA) et aux véhicules aériens sans équipage (UAV).
            </Box>
        </Box>
        <hr />
        <hr />
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                VISION
            </Flex>
            <Box className={css(styles.textbox)}>
            CREATE TRAVERSAL imagine le Canada à l'avant-garde de l'innovation dans les technologies autonomes fiables,
            sûres, sécurisées, intelligentes et responsables.
            </Box>
        </Box>
        <hr />
    </>
  )
};

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
    }
})

export default MissionVisionFr