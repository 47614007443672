import React from 'react';
import { Flex, Box, SimpleGrid, Image } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { StyleSheet, css } from 'aphrodite';

function Footer() {
  return (
    <>
    <Flex className={css(styles.parent)}>
        <Flex className={css(styles.container1)}>
            <Box justifyContent="center">
                <Image src="NSERC_RGB.png" alt="nserc logo" boxSize="20%" fit="fit" />
            </Box>
            <Box textAlign="left" fontWeight="bold">
                We acknowledge the support of the Natural Sciences and Engineering Research Council of Canada (NSERC).
            </Box>
        </Flex>
        <Flex className={css(styles.container2)}>
            <SimpleGrid columns={2} spacing={5} textAlign='center'>
                <Box className={css(styles.links)}>
                    <Link to='/'>
                        HOME
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/about-us'>
                        ABOUT US
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/program'>
                        PROGRAM
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/seminars'>
                        SEMINARS
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/seminars'>
                        PUBLICATIONS
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/seminars'>
                        NEWS
                    </Link>
                </Box>
                <Box className={css(styles.links)}>
                    <Link to='/seminars'>
                        CONTACT US
                    </Link>
                </Box>
            </SimpleGrid>
        </Flex>
    </Flex>
    </>
  )
};

const styles = StyleSheet.create({
    parent: {
        backgroundColor: '#e2e1dc',
        paddingTop: '1%',
        paddingBottom: '1%',
        // position: 'sticky',
        bottom: 0,
        width: '100%'
    },
    container1: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block',
        paddingLeft: 10,
        paddingRight: 10
    },
    container2: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block'
    },
    links: {
        fontWeight: 'bold',
        ":hover": {
            textDecoration: 'underline',
            color: '#DC242C'
        }
    }
})

export default Footer