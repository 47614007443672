import FooterFr from 'components/FooterFr'
import MobileFooterFr from 'components/MobileFooterFr';
import MobileNavbarFr from 'components/MobileNavbarFr';
import NavbarFr from 'components/NavbarFr'
import PublicationsListFr from 'components/Publications/PublicationListFr';
import React from 'react';
import { useSelector } from 'react-redux';

function PublicationsFr() {
  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbarFr />
      <PublicationsListFr />
      <MobileFooterFr />
      </>
    ) : (
      <>
      <NavbarFr />
      <PublicationsListFr />
      <FooterFr />
      </>
    )}
    </>
  )
}

export default PublicationsFr