import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { StyleSheet, css } from "aphrodite";
import { useSelector } from 'react-redux';

function Hackathon() {

    const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    <Flex className={css(styles.container)}>
        HACKATHON
    </Flex>
    <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
    The TRAVERSAL Hackathon is an annual event that brings together second-year graduate students from the program.
    Co-chaired by two co-applicants, this intensive 48-hour event challenges students to collaborate in diverse teams
    and tackle research problems related to autonomous decision-making for AV-UAVs.
    </Box>
    <hr /> 
    </>
    )
};

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        fontSize: 18
    },
})

export default Hackathon