import {
    Flex,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function TrainingFr () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                FORMATION
            </Flex>
            <Flex className={css(styles.accordion)}>
                <Accordion Accordion allowToggle width='100%' allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                                Ateliers de sondage et de tutoriels sur les systèmes VA-UAV.
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            Les ateliers de sondage et de tutoriels sur les systèmes VA-UAV (véhicules autonomes-véhicules aériens sans équipage)
                            constituent un élément vital du programme proposé. Ces ateliers sont conçus pour fournir aux stagiaires une formation complète
                            dans la réalisation de sondages et de tutoriels sur des sujets de pointe dans le domaine des systèmes VA-UAV.
                            L'objectif est d'équiper les stagiaires des compétences nécessaires pour explorer et comprendre les derniers développements, les défis et
                            les opportunités dans leurs domaines de recherche respectifs.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                                Nouvelle filière de cours de niveau supérieur pour les diplômés 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            Le programme CREATE TRAVERSAL s'apprête à introduire une gamme de cours de niveau supérieur à la pointe de la technologie. Ces cours,
                            offerts à la fois sur le campus et à distance, couvrent des domaines critiques dans le domaine des systèmes autonomes.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={20}
                            fontWeight='bold'>
                            Compétences professionnelles et formation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            L'atelier <b>annuel TRAVERSAL</b> est un événement clé où les stagiaires interagissent avec des experts de l'industrie et du milieu universitaire
                            à travers des conférences, des panels et des présentations de recherche. Cette réunion favorise les opportunités de réseautage et explore des thèmes
                            alignés sur la portée du programme.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
        marginTop: 100
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})

export default TrainingFr