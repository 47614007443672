import Navbar from "components/Navbar"
import React from "react";
import Training from "components/Program/Training";
import Internship from "components/Program/Internship";
import InternationalExchanges from "components/Program/InternationalExchanges";
import HowToApply from "components/Program/HowToApply";
import Footer from "components/Footer";
import Hackathon from "components/Program/Hackathon";
import { useSelector } from "react-redux";
import MobileNavbar from "components/MobileNavbar";
import MobileFooter from "components/MobileFooter";

function Program () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return(
        <>
        {isMobile ? (
            <>
            <MobileNavbar />
            <Training />
            <Hackathon />
            <Internship />
            <InternationalExchanges />
            <HowToApply />
            <MobileFooter />
            </>
        ) : (
            <>
            <Navbar />
            <Training />
            <Hackathon />
            <Internship />
            <InternationalExchanges />
            <HowToApply />
            <Footer />
            </>
        )}
        </>
    )
}

export default Program