import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { StyleSheet, css } from "aphrodite";

function HackathonFr() {
  return (
    <>
    <Flex className={css(styles.container)}>
        HACKATHON
    </Flex>
    <Box className={css(styles.textbox)}>
    Le Hackathon TRAVERSAL est un événement annuel qui rassemble les étudiants de deuxième année du programme.
    Co-présidé par deux co-demandeurs, cet événement intensif de 48 heures met au défi les étudiants de collaborer
    au sein d'équipes diverses et de relever des problèmes de recherche liés à la prise de décision autonome pour les VA-UAV
    (véhicules autonomes-véhicules aériens sans équipage).
    </Box>
    <hr /> 
    </>
    )
};

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        textAlign: 'left',
        fontSize: 18
    },
})

export default HackathonFr