import React from 'react';
import {
    Flex, Box, SimpleGrid, Image,
    Text
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from 'react-redux';

function Collaborators() {

    const isMobile = useSelector(state=> state.windowSize.isMobile);

  return (
<Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                COLLABORATORS
            </Flex>
            <SimpleGrid columns={2} spacing={10} textAlign="center" marginTop={10} marginBottom={10}>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="GerdLeuchs.jpg" alt="Gerd Leuchs" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="GerdLeuchs.jpg" alt="Gerd Leuchs" className={css(styles.images)}/>
                            </Flex>
                        )}
                        Gerd Leuchs
                        <Text className={css(styles.title)}>
                            Max Planck Institute, Germany
                        </Text>
                    </Flex>
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="GaryBurnett.png" alt="Gary Burnett" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="GaryBurnett.png" alt="Gary Burnett" className={css(styles.images)}/>
                            </Flex>
                        )}
                        Gary Burnett
                        <Text className={css(styles.title)}>
                            University of Nottingham
                        </Text>
                    </Flex>
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="YacineGhamri.png" alt="Yacine Ghamri" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="YacineGhamri.png" alt="Yacine Ghamri" className={css(styles.images)}/>
                            </Flex>
                        )}
                            Yacine Ghamri-Doudane
                        <Text className={css(styles.title)}>
                            La Rochelle University
                        </Text>
                    </Flex>
                </Box>

                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="Liam.png" alt="Liam Peyton" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="Liam.png" alt="Liam Peyton" className={css(styles.images)}/>
                            </Flex>
                        )}
                            Liam Peyton
                        <Text className={css(styles.title)}>
                            University of Ottawa
                        </Text>
                    </Flex>
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="Damla.jpg" alt="Damla Turgut" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="Damla.jpg" alt="Damla Turgut" className={css(styles.images)}/>
                            </Flex>
                        )}
                            Damla Turgut
                        <Text className={css(styles.title)}>
                            University of Central Florida
                        </Text>
                    </Flex>
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        {isMobile ? (
                            <Flex justifyContent="center">
                                <Image src="Michele.png" alt="Michele Nogueira" className={css(styles.mobileImage)}/>
                            </Flex>
                        ) : (
                            <Flex justifyContent="center">
                                <Image src="Michele.png" alt="AA" className={css(styles.images)}/>
                            </Flex>
                        )}
                            Michele Nogueira
                        <Text className={css(styles.title)}>
                            Federal University of Minas Gerais
                        </Text>
                    </Flex>
                </Box>
            </SimpleGrid>
        </Box>
  )
};

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
        textAlign: 'center'
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
        fontWeight: 'normal'
    },
    images: {
        width: "25%",
        fit: "fit",
        borderRadius: "50%",
    },
    mobileImage: {
        width: "70%",
        borderRadius: '50%'
    },
})

export default Collaborators