import MissionVision from 'components/Home/MissionVision';
import Carousel from '../components/Home/Carousal';
import MobileCarousel from '../components/Home/MobileCarousal';
import Carousel2 from 'components/Home/Carousal2';
import Navbar from '../components/Navbar';
import Goal from 'components/Home/Goal';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileCarousel2 from 'components/Home/MobileCarousal2';
import MobileFooter from 'components/MobileFooter';

function Home() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <MobileCarousel />
        <MissionVision />
        <MobileCarousel2 />
        <Goal />
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <Carousel />
        <hr />
        <MissionVision />
        <Carousel2 />
        <hr />
        <Goal />
        <Footer />
      </>
    )}
    </>
  );
}

export default Home;
