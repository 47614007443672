import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';

function MobileFooter() {
  return (
    <>
    <Flex className={css(styles.parent)}>
        <Flex className={css(styles.container1)}>
            <Image src="NSERC_RGB.png" alt="nserc logo" boxSize="60%" width="90%" fit="contain" />
        </Flex>
        <Flex fontWeight="bold" className={css(styles.container2)}>
            We acknowledge the support of the Natural Sciences and Engineering Research Council of Canada (NSERC).
        </Flex>
    </Flex>
    </>
  )
};

const styles = StyleSheet.create({
    parent: {
        backgroundColor: '#e2e1dc',
        paddingTop: '3%',
        paddingBottom: '3%',
        // position: 'fixed',
        bottom: 0,
        width: '100%'
    },
    container1: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block',
        paddingLeft: 10,
        paddingRight: 10
    },
    container2: {
        justifyContent: 'center',
        color: 'black',
        width: '50%',
        display: 'block'
    },
    links: {
        fontWeight: 'bold',
        ":hover": {
            textDecoration: 'underline'
        }
    }
})

export default MobileFooter