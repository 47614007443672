import React from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import ProgramComm from "components/AboutUs/ProgramComm";
import { useSelector } from 'react-redux';
import MobileNavbar from "components/MobileNavbar";
import MobileFooter from "components/MobileFooter";

function AboutUs () {
    const isMobile = useSelector(state=> state.windowSize.isMobile);
    return(
        <>
        {isMobile ? (
            <>
            <MobileNavbar />
            <ProgramComm />
            <MobileFooter />
            </>
        ) : (
            <>
            <Navbar />
            <ProgramComm />
            <Footer />
            </>
        )}
        </>
    )
};

export default AboutUs