import React, { useState, useEffect } from "react";
import { StyleSheet, css } from 'aphrodite';

import image1 from "../../assets/LabImage4.png";
import image2 from "../../assets/LabImage5.png";
import image3 from "../../assets/LabImage2.png";
import { Flex, Image } from "@chakra-ui/react";

const images = [image1, image2, image3 /* Add more imported images here */];


function Carousel2() {
  const [currentImage, setCurrentImage] = useState(images.length - 1);;
  const [transitioning, setTransitioning] = useState(false);

  const nextSlide = () => {
    if (!transitioning) {
      setTransitioning(true);
      setTimeout(() => {
        setCurrentImage((prevImage) =>
          prevImage === images.length - 1 ? 0 : prevImage + 1
        );
        setTransitioning(false);
      }, 500); // Adjust the transition duration (in milliseconds)
    }
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // Change image every 2 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex className={css(styles.carousal)}>
        <Flex className={css(styles.slideContainer)}>
        {images.map((_, index) => (
            <Flex
                key={index}
                className={css(styles.slide, currentImage === index && styles.currentSlide, transitioning && styles.slideTransition)}
                onClick={() => setCurrentImage(index)}
            >
                <Image
                src={images[currentImage]}
                alt={`Slide ${currentImage + 1}`}
                style={{
                    justifyContent: 'center',
                    objectFit: 'contain',
                    width: '75%',
                    height: '50%'
                }}
                />
            </Flex>
            ))}
      </Flex>
    </Flex>
  );
}

const styles = StyleSheet.create({
    carousal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '70vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
    },
    slideContainer: {
        display: "flex",
        transition: "transform 0.5s ease", // CSS transition for sliding effect
        alignContent: 'center',
        justifyContent: 'center',
        minWidth: '100vw',
      },
      slide: {
        flex: "0 0 100%",
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    imageStyle: {
        alignContent: 'center',
    },
    currentSlide: {
        transform: "translateX(0%)", // Start from the right-most image
      },
      slideTransition: {
        transform: "translateX(-100%)", // Slide transition effect
      },
})

export default Carousel2;
