import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function GoalFr() {
  return (
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                OBJECTIF
            </Flex>
            <Box className={css(styles.textbox)}>
                Renforcer la main-d'œuvre et le pipeline de talents du secteur des véhicules connectés et autonomes (VCA)
                au Canada grâce à des modules de formation stratégiquement conçus et des stages en entreprise, en
                collaboration entre le milieu universitaire et l'industrie, afin de pallier les pénuries de compétences.
            </Box>
        </Box> 
    )
}

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    }
})

export default GoalFr