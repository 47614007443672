import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function InternationalExchangesFr () {

    const isMobile = useSelector(state => state.windowSize.isMobile);
    
    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                ÉCHANGES INTERNATIONAUX
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            Les étudiants en master et en doctorat inscrits dans notre programme auront l'occasion exceptionnelle de participer à un programme
            d'échanges internationaux, qui sera attribué sur une base compétitive. Cette initiative est conçue pour offrir à nos étudiants
            une expérience unique et enrichissante qui dépasse les frontières de la formation.
            <p />
            <br />
            Le programme TRAVERSAL étend sa portée à l'échelle mondiale grâce à une initiative d'échange international. En plus des stages en entreprise,
            les étudiants ont la possibilité de participer à des projets de recherche et à des collaborations avec des institutions partenaires aux États-Unis,
            au Brésil, en Allemagne, au Royaume-Uni et en France. Ce programme d'échange enrichit leurs perspectives, favorise l'exposition à la recherche
            internationale et encourage la collaboration sur des projets de pointe. Nous proposons également des stages virtuels pour nous adapter aux conditions
            de voyage en constante évolution. Toutes les opportunités sont offertes de manière compétitive et équitable, sous la supervision des conseillers institutionnels
            en équité, diversité et inclusion (EDI).
            </Box>
            <hr />
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        // textAlign: 'left',
        fontSize: 18
    },
})

export default InternationalExchangesFr 