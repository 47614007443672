import React from 'react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';
import NewsCarousel from 'components/News/NewsCarousal';
import NewsCards from 'components/News/NewsCards';
import MobileNewsCarousel from 'components/News/MobileNewsCarousal';

function News() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbar />
      <MobileNewsCarousel />
      <NewsCards />
      <MobileFooter />
      </>
    ) : (
      <>
      <Navbar />
      <NewsCarousel />
      <NewsCards />
      <Footer />
      </>
    )}
    </>
  )
}

export default News