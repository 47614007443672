import {
    Flex,
    Box,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function HowToApply() {

    const isMobile = useSelector(state => state.windowSize.isMobile)
  return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                CONFERENCES
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            The conferences organized within the TRAVERSAL program
            are designed to advance the program's goals and objectives. These events bring
            together leading experts, researchers, and professionals in the fields of communication,
            artificial intelligence (AI), physics, and operations research, all with a common aim: to drive innovation
            and excellence within the program.
            </Box>
            <hr />
            <Flex className={css(styles.container)}>
                HOW TO APPLY
            </Flex>
            <Flex className={css(styles.accordion)}>
                <Accordion Accordion allowToggle width='100%' allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                                1. Prepare Your Application Documents
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        <b>Curriculum Vitae (CV):</b> Create a comprehensive CV that highlights your educational background,
                        research experience, academic achievements, and any relevant professional experience.
                        <br />
                        <br />
                        <b>Educational Transcripts:</b> Gather your educational transcripts, including undergraduate and, if
                        applicable, postgraduate degrees. Ensure that these documents clearly show your academic history, grades, and qualifications.
                        <br />
                        <br />
                        <b>Recent Published Works:</b> Include recent published works, such as research papers, articles, or projects, that showcase your
                        academic and research capabilities. Make sure to provide the name(s) of your supervisor(s) for context.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            2. Compile Your Application Package 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Combine all the required documents into a single electronic package, preferably in PDF format,
                        to facilitate efficient processing.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            3. Email Your Application
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Send your complete application package to our dedicated email address:
                        <br />
                        <b>Email Address: scvic-civic@uottawa.ca</b>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            4. Subject Line
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        In the subject line of your email, please specify "CREATE TRAVERSAL Application -
                        [Your Full Name]." This will help us quickly identify and process your application.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            5. Application Deadline
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        The trainee program accepts applications throughout the year; however, it's important to note
                        that there is a limited number of slots available annually.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            6. Await Confirmation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Upon receiving your application, our team will acknowledge its receipt.
                        Please be patient as we review and assess all applications.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            7. Interview and Evaluation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Shortlisted candidates will be contacted for interviews and further evaluation.
                        This is an opportunity for us to learn more about your qualifications, research interests,
                        and potential fit within the program.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            We encourage applications from individuals belonging to the four designated groups: Aboriginal Peoples,
            Visible Minorities, Persons with Disabilities, Women, and Persons from Gender Minorities, as delineated
            in the equity objectives outlined in the Employment Equity Act. 
            <br />
            <br />
            We appreciate your interest in the CREATE TRAVERSAL program and look forward to receiving your application.
            If you have any questions or require further assistance during the application process, please do not hesitate
            to contact us. We wish you the best of luck with your application and hope to welcome you into our program soon.
            </Box>
            <hr />
        </Box>
    )
};

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        // textAlign: 'left',
        fontSize: 18
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})


export default HowToApply