import {
    Flex, Box, SimpleGrid, Image
    // Text
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function CoInvestigators() {

    const isMobile = useSelector(state=> state.windowSize.isMobile);

  return (
    <Box className={css(styles.parent)}>
        <Flex className={css(styles.container)}>
            CO-INVESTIGATORS
        </Flex>
        <SimpleGrid columns={2} spacing={10} textAlign="center" marginTop={10} marginBottom={10}>
            {/* For english mobile version */}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="LionelBriand.jpg" alt="Lionel Briand" className={css(styles.mobileImage)} />
                    </Flex>
                    Lionel Briand
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                // For english desktop version
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        {/* Image part imported from 'public directory' */}
                        <Image src="LionelBriand.jpg" alt="Lionel Briand" className={css(styles.images)}/> 
                    </Flex>
                    Lionel Briand
                    <Flex className={css(styles.title)}>
                    University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="MelikeKantarci.jpg" alt="Melike Erol-Kantarci" className={css(styles.mobileImage)}/>
                    </Flex>
                    Melike Erol-Kantarci
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="MelikeKantarci.jpg" alt="Melike Erol-Kantarci" className={css(styles.images)} />
                    </Flex>
                    Melike Erol-Kantarci
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="OctaviaDobre.jpg" alt="Octavia Dobre" className={css(styles.mobileImage)}/>
                    </Flex>
                    Octavia Dobre
                    <Flex className={css(styles.mobileTitle)}>
                        Memorial University
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="OctaviaDobre.jpg" alt="Octavia Dobre" className={css(styles.images)}/>
                    </Flex>
                    Octavia Dobre
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="EbrahimKarimi.jpg" alt="Ebrahim Karimi" className={css(styles.mobileImage)} />
                    </Flex>
                    Ebrahim Karimi
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="EbrahimKarimi.jpg" alt="Ebrahim Karimi" className={css(styles.images)} />
                    </Flex>
                    Ebrahim Karimi
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="MoradBenyoucef.jpeg" alt="Morad Benyoucef" className={css(styles.mobileImage)}/>
                    </Flex>
                    Morad Benyoucef
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="MoradBenyoucef.jpeg" alt="Morad Benyoucef" className={css(styles.images)}/>
                    </Flex>
                    Morad Benyoucef
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="RobertLaganiere.jpg" alt="Robert Laganière" className={css(styles.mobileImage)}/>
                    </Flex>
                    Robert Laganière 
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="RobertLaganiere.jpg" alt="Robert Laganière" className={css(styles.images)} />
                    </Flex>
                    Robert Laganière 
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="FrodualdKabanza.jpg" alt="Froduald Kabanza" className={css(styles.mobileImage)}/>
                    </Flex>
                    Froduald Kabanza 
                    <Flex className={css(styles.mobileTitle)}>
                        University de Sherbrooke
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="FrodualdKabanza.jpg" alt="Froduald Kabanza" className={css(styles.images)} />
                    </Flex>
                    Froduald Kabanza 
                    <Flex className={css(styles.title)}>
                        University de Sherbrooke
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="BirsenDonmez.jpg" alt="Birsen Donmez" className={css(styles.mobileImage)}/>
                    </Flex>
                    Birsen Donmez
                    <Flex className={css(styles.mobileTitle)}>
                        University of Toronto
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="BirsenDonmez.jpg" alt="Birsen Donmez" className={css(styles.images)}/>
                    </Flex>
                    Birsen Donmez
                    <Flex className={css(styles.title)}>
                        University of Toronto
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)}>
                    <Flex justifyContent="center">
                        <Image src="ElizabethJudge.jpg" alt="Elizabeth Judge" className={css(styles.mobileImage)} />
                    </Flex>
                    Elizabeth Judge
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)} marginBottom={5}>
                    <Flex justifyContent="center">
                        <Image src="ElizabethJudge.jpg" alt="Elizabeth Judge" className={css(styles.images)}/>
                    </Flex>
                    Elizabeth Judge
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
            {isMobile ? (
                <Box className={css(styles.names)} alignItems='center'>
                     <Flex justifyContent="center">
                        <Image src="Sandra.png" alt="Elizabeth Judge" className={css(styles.mobileImage)} />
                    </Flex>
                        Sandra Schillo
                    <Flex className={css(styles.mobileTitle)}>
                        University of Ottawa
                    </Flex>
                </Box>
            ) : (
                <Box className={css(styles.names)} marginBottom={5} alignItems='center'>
                    <Flex justifyContent="center">
                        <Image src="Sandra.png" alt="Elizabeth Judge" className={css(styles.images)} />
                    </Flex>
                        Sandra Schillo
                    <Flex className={css(styles.title)}>
                        University of Ottawa
                    </Flex>
                </Box>
            )}
        </SimpleGrid>
    </Box>
    )
};

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
        textAlign: 'center'
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'center',
        alignItems: 'center'
    },
    mobileTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        textAlign: 'center',
        justifyContent: 'center'
    },
    title: {
        fontSize: 18,
        fontWeight: 'normal',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    images: {
        width: "25%",
        fit: "fit",
        borderRadius: "50%",
    },
    mobileImage: {
        width: '70%',
        borderRadius: "50%"
    }
})


export default CoInvestigators