import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function MissionVision() {
  return (
    <>
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                MISSION
            </Flex>
            <Box className={css(styles.textbox)}>
            <b>CREATE TRAVERSAL</b> is dedicated to endowing HQP with both technical and soft skills,
            promoting interdisciplinary collaboration, and propelling Canada to the forefront of
            autonomous technology leadership through autonomous vehicles
            (AVs) and uncrewed aerial vehicles (UAVs).
            </Box>
        </Box>
        <hr />
        <hr />
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                VISION
            </Flex>
            <Box className={css(styles.textbox)}>
            CREATE TRAVERSAL envisions Canada at the forefront of innovation in trustworthy,
            safe, secure, smart, and responsible autonomous technologies.
            </Box>
        </Box>
        <hr />
    </>
  )
};

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
    }
})

export default MissionVision