import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function Goal() {
  return (
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                GOAL
            </Flex>
            <Box className={css(styles.textbox)}>
            To strengthen Canada's connected and autonomous vehicles
            (CAV) workforce and talent pipeline through strategically designed training
            modules and industrial internships in collaboration between academia and industry
            so as to overcome skill shortages.
            </Box>
        </Box> 
    )
}

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    }
})

export default Goal