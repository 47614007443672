import React from 'react';
import { Box } from '@chakra-ui/react';
import { css, StyleSheet } from 'aphrodite';
import { useSelector } from 'react-redux';

function DescriptionFr() {
  const isMobile = useSelector(state=> state.windowSize.isMobile)
  return (
    <>
    {/* <Flex className={css(styles.container)}>
        DESCRIPTION
    </Flex> */}
    <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
    Les séminaires TRAVERSAL aborderont un large éventail de sujets, y compris les développements
    de pointe dans divers domaines de la recherche, le transfert de technologie et l'entrepreneuriat.
    Avec la participation de conférenciers éminents du monde entier, les séminaires offrent une occasion
    unique de partage de connaissances, de collaboration et de réseautage. 
    </Box>
    <hr />
    </>
  )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        textShadow: '1px 1px gray',
        marginTop: 75
    },
    textbox: {
      margin: '2%',
      marginTop: 100,
      marginBottom: 10,
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 'bolder',
  },
})

export default DescriptionFr