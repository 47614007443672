import React from 'react';
import { Box, Flex } from '@chakra-ui/react'
import { StyleSheet, css } from 'aphrodite';
import { useSelector } from 'react-redux';
import TEMSeminar from './TEMSeminar';
import TTESeminar from './TTESeminar';

function Technical() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <Box className={css(styles.head)}>
        <Flex className={css(styles.container)} textAlign={isMobile ? 'center' : 'left'}>
        TECHNICAL - ETHICAL MENTORSHIP SEMINAR SERIES
        </Flex>
        <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
        <b>The Technical-Ethical Mentorship Seminar Series</b>, abbreviated as TEM,
        is an integral component of the TRAVERSAL program. This series provides trainees
        with valuable insights into the latest technical advancements in the field of
        autonomous vehicles (AVs) and uncrewed aerial vehicles (UAVs). Additionally,
        TEM addresses the legal and ethical dimensions associated with AV and UAV-based services.
        It consists of a minimum of eight seminars per year, ensuring that trainees receive
        comprehensive knowledge and guidance in both technical and ethical aspects of their field.
        Through TEM, trainees gain a well-rounded understanding of the complex and evolving
        landscape of AV and UAV technologies.
        </Box>
        <TEMSeminar />
        <hr />
        <Flex className={css(styles.container)} textAlign={isMobile ? 'center' : 'left'}>
        TECHNOLOGY TRANSFER ENTREPRENEURSHIP:
        </Flex>
        <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
        The Technology Transfer Entrepreneurship aspect of the TRAVERSAL program focuses
        on equipping trainees with the knowledge and skills required for successful technology
        readiness and commercialization. This segment provides strategies for taking research
        outcomes from the program and translating them into viable commercial products or services.
        Trainees receive seminar support through technology transfer offices and benefit from the
        expertise of alumni from startup accelerators. To ensure comprehensive training, trainees
        are required to attend a minimum of four seminars in each stream per year. This emphasis on
        technology transfer and entrepreneurship empowers trainees to effectively navigate the journey
        from research to real-world applications, fostering innovation and economic impact.
        </Box>
        <TTESeminar />
        <hr />
    </Box>
  )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: 0,
        marginBottom: 10,
        // textAlign: 'left',
        fontSize: 18,
    },
})

export default Technical