import Footer from 'components/Footer'
import MobileFooter from 'components/MobileFooter';
import MobileNavbar from 'components/MobileNavbar';
import Navbar from 'components/Navbar'
import PublicationsList from 'components/Publications/PublicationsList';
import React from 'react';
import { useSelector } from 'react-redux';

function Publications() {
  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbar />
      <PublicationsList />
      <MobileFooter />
      </>
    ) : (
      <>
      <Navbar />
      <PublicationsList />
      <Footer />
      </>
    )}
    </>
  )
}

export default Publications