import React from "react";
import {
    Flex,
    Box,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel
} from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function InternshipFr () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                STAGES
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
                Les stagiaires auront l'opportunité de participer à un programme de stage en entreprise qui explore divers
                aspects de notre programme en collaboration avec des partenaires industriels de renom. Ces stages comprennent:
            </Box>
            <Flex className={css(styles.accordion)}>
                <Accordion width='100%' allowToggle allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                                Stages en intelligence artificielle fiable et responsable
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            Plongez dans les dimensions éthiques et responsables de l'intelligence artificielle pendant votre stage. Acquérez
                            une expérience pratique pour garantir que les systèmes d'IA sont construits en tenant compte de la fiabilité et des considérations éthiques.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            Formation en cybersécurité et en cybersécurité physique 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            Rejoignez nos partenaires industriels pour une formation spécialisée en cybersécurité et en aspects cyber-physiques. Développez une expertise dans la
                            protection des systèmes numériques et physiques contre les menaces et les vulnérabilités.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            Formation en communication, capteurs et facteurs humains
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                            Améliorez vos compétences en communication, en technologie des capteurs et en facteurs humains en participant à des sessions de formation offertes par
                            nos partenaires industriels. Explorez les composantes essentielles de la communication efficace et des principes de conception centrés sur l'humain.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Box>
    )
}

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: 0,
        marginBottom: 0,
        // textAlign: 'left',
        fontSize: 18,
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})

export default InternshipFr