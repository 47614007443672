import ContactFormFr from 'components/ContactUs/ContactFormFr'
import FooterFr from 'components/FooterFr'
import NavbarFr from 'components/NavbarFr'
import React from 'react'

function ContactUsFr() {
  return (
    <>
    <NavbarFr />
    <ContactFormFr />
    <FooterFr />
    </>
  )
}

export default ContactUsFr