import {
    Flex,
    Box,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function HowToApplyFr() {
    const isMobile = useSelector(state=>state.windowSize.isMobile)

  return (
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                CONFÉRENCES
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
                Les conférences organisées dans le cadre du programme TRAVERSAL sont conçues pour faire progresser les objectifs du programme.
                Ces événements réunissent des experts de premier plan, des chercheurs et des professionnels dans les domaines de la communication,
                de l'intelligence artificielle (IA), de la physique et de la recherche opérationnelle, tous ayant un objectif commun : promouvoir
                l'innovation et l'excellence au sein du programme.
            </Box>
            <hr />
            <Flex className={css(styles.container)}>
                COMMENT POSTULER
            </Flex>
            <Flex className={css(styles.accordion)}>
                <Accordion Accordion allowToggle width='100%' allowMultiple>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                                1. Préparez vos documents de candidature
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        <b>Curriculum Vitae (CV):</b> Créez un CV complet mettant en avant votre parcours éducatif, votre expérience de recherche,
                        vos réalisations académiques et toute expérience professionnelle pertinente.
                        <br />
                        <br />
                        <b>Relevés de notes :</b> Rassemblez vos relevés de notes académiques, y compris ceux de votre premier cycle et, le cas échéant,
                        de vos études supérieures. Assurez-vous que ces documents montrent clairement votre historique académique, vos notes et vos qualifications.
                        <br />
                        <br />
                        <b>Travaux récemment publiés :</b> Incluez des travaux récemment publiés, tels que des articles de recherche,
                        des publications ou des projets, mettant en valeur vos compétences académiques et de recherche.
                        Assurez-vous de fournir le nom de votre ou vos superviseurs pour le contexte.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box
                            as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            2. Rassemblez votre dossier de candidature 
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Regroupez tous les documents requis en un seul dossier électronique, de préférence au format PDF, pour faciliter le traitement efficace de votre candidature.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            3. Envoyez votre candidature par e-mail
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Envoyez votre dossier de candidature complet à notre adresse e-mail dédiée :
                        <br />
                        <b>Adresse e-mail : scvic-civic@uottawa.ca</b>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            4. Objet du message
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Dans la ligne d'objet de votre e-mail, veuillez préciser "Candidature CREATE TRAVERSAL - [Votre nom complet]".
                        Cela nous aidera à identifier et à traiter rapidement votre candidature.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            5. Date limite de candidature
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Le programme de stagiaires accepte les candidatures tout au long de l'année ; cependant, veuillez noter qu'il existe un nombre
                        limité de places disponibles chaque année.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            6. Attendez la confirmation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Dès réception de votre candidature, notre équipe vous en accusera réception. Soyez patient pendant que nous examinons et évaluons toutes les candidatures.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span"
                            flex='1'
                            textAlign='left'
                            fontSize={18}
                            fontWeight='bold'>
                            7. Entretien et évaluation
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} fontSize={16} textAlign={isMobile ? 'center' : 'left'}>
                        Les candidats présélectionnés seront contactés pour des entretiens et une évaluation supplémentaire. C'est l'occasion pour nous d'en apprendre davantage sur vos qualifications,
                        vos intérêts de recherche et votre adéquation potentielle avec le programme. Le programme TRAVERSAL promeut l'équité, la diversité et l'inclusion dans les sphères éducatives et de leadership de nos stagiaires.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
            <Box className={css(styles.textbox)} textAlign={isMobile ? 'center' : 'left'}>
            Nous encourageons les candidatures de personnes appartenant aux quatre groupes désignés : les peuples autochtones, les minorités visibles, les personnes handicapées, les femmes et les personnes des
            minorités de genre, tels que définis dans les objectifs d'équité énoncés dans la Loi sur l'équité en matière d'emploi. 
            <br />
            <br />
            Nous vous remercions de votre intérêt pour le programme CREATE TRAVERSAL et nous attendons avec impatience de recevoir votre candidature. Si vous avez des questions ou avez besoin d'une assistance
            supplémentaire pendant le processus de candidature, n'hésitez pas à nous contacter. Nous vous souhaitons bonne chance pour votre candidature et espérons vous accueillir bientôt dans notre programme.
            </Box>
            <hr />
        </Box>
    )
};

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        marginBottom: '1%',
        // textAlign: 'left',
        fontSize: 18
    },
    accordion: {
        maxWidth: '100vw',
        textAlign: 'center',
        paddingBottom: 0,
        margin: '1%'
    }
})


export default HowToApplyFr