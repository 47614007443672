import Navbar from 'components/Navbar'
import React from 'react'
import ContactForm from 'components/ContactUs/ContactForm';
import Footer from 'components/Footer';
import { useSelector } from 'react-redux';
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

function ContactUs() {

  const isMobile = useSelector(state=> state.windowSize.isMobile)
  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbar />
      <ContactForm />
      <MobileFooter />
      </>
    ) : (
      <>
      <Navbar />
      <ContactForm />
      <Footer />
      </>
    )}
    </>
  )
}

export default ContactUs