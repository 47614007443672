import React from 'react';
import {Flex,
        Image,
        Drawer,
        DrawerBody,
        DrawerFooter,
        DrawerHeader,
        DrawerOverlay,
        DrawerContent,
        useDisclosure,
        Button,
        IconButton,
        Box,
    } from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom"; // Import useLocation and Link

function MobileNavbar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  return (
    <>
    <Box className={css(styles.container)}>
        <Flex >
            <Box height="5%">
                <Link to='/'>
                    <Image src="traversal_logo.png" alt="nserc logo" boxSize="40%" fit="contain" />
                </Link>
            </Box>
            <Flex width="100%" alignItems='center'>
                <IconButton ref={btnRef} onClick={onOpen} size='lg' variant='ghost' icon={<FaBars />} className={css(styles.icon)}/>
            </Flex>
        </Flex>
        <Flex className={css(styles.slidingTab)}>
            <Flex className={css(styles.content)}>
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            </Flex>
        </Flex>
    </Box>

    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
            <DrawerHeader>
                <Flex alignItems='center'>
                    <Flex width="100%">
                        Explore NSERC
                    </Flex>
                    <Flex justifyContent="right" width="100%">
                        <Button variant='solid'>
                            <Link to='/fr'>
                                En/Fr
                            </Link>
                        </Button>
                    </Flex>
                </Flex>
            </DrawerHeader>
          
          <DrawerBody width="100%">
            <Flex className={css(styles.DrawerContent)}>
                <Flex className={css(styles.links)}>
                    <Link to='/'>
                        HOME
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/about-us'>
                        ABOUT US
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/program'>
                        PROGRAM
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/seminars'>
                        SEMINARS
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/publications'>
                        PUBLICATIONS
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/news'>
                        NEWS
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/contact-us'>
                        CONTACT US
                    </Link>
                </Flex>
                <hr />
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
};

const styles = StyleSheet.create({
    container: {
        width: '100vw',
        backgroundColor: ' #e2e1dc  ' ,
        position: 'fixed',
        top: 0,
        zIndex: 10
    },
    icon: {
        justifyContent: 'right',
        width: '100%',
        padding: 0,
        marginRight: '5%',
        marginTop: 5,
        ':hover': {
            backgroundColor: '#e2e1dc'
        }
    },
    DrawerContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    links: {
        fontWeight: 'bold',
        marginBottom: 5,
        justifyContent: 'center',
        width: '100%',
    },
    slidingTab: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      },
    content: {
        animationName: {
            '0%': {
                transform: 'translateX(100%)',
            },
            '100%': {
                transform: 'translateX(-100%)',
            },
        },
        animationDuration: '10s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        color: 'black', // Set text color to white or your preferred color
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        left: 0,
        width: '100%', // Ensure the content takes up the full width
    },
})

export default MobileNavbar