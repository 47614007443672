import {
    Flex, Box, SimpleGrid, Image,
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import CoInvestigators from "./CoInvestigators";
import Collaborators from "./Collaborators";
import { useSelector } from "react-redux";
import IndustryPartners from "./IndustryPartners";

function ProgramComm () {

    const isMobile = useSelector(state=> state.windowSize.isMobile);

    return(
        <>
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                CREATE TRAVERSAL TEAM 
            </Flex>
            <SimpleGrid columns={2} spacing={10} textAlign="center" marginTop={5} marginBottom={5}>
                {isMobile ? (
                    <Box justifyContent="center" alignContent="center">
                        <Flex justifyContent="center">
                            <Image src="burak.png" alt="Burak Kantarci" height="75%" width="70%" fit="fit" borderRadius="50%" />
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.names)}>
                            Burak Kantarci
                        </Flex>
                        <Flex flexDirection="column" fontSize={16}>
                            Professeur, Directeur du Programme
                        </Flex>
                    </Box>
                ) : (
                    <Box justifyContent="center" alignContent="center">
                        <Flex justifyContent="center">
                            <Image src="burak.png" alt="Burak Kantarci" className={css(styles.images)} />
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.names)}>
                            Burak Kantarci
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.title)}>
                            Professeur, Directeur de programme
                        </Flex>
                    </Box>
                )}
                {isMobile ? (
                    <Box justifyContent="center" alignContent="center">
                        <Flex justifyContent="center">
                            <Image src="Ade.png" alt="Adeyinka Ogunlami" width="70%" fit="fit" borderRadius="50%" />
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.names)}>
                            Adeyinka Ogunlami
                        </Flex>
                        <Flex flexDirection="column" fontSize={16}>
                            Coordonnateur de programme
                        </Flex>
                    </Box>
                ) : (
                    <Box justifyContent="center" alignContent="center">
                        <Flex justifyContent="center">
                            <Image src="Ade.png" alt="Adeyinka Ogunlami" className={css(styles.images)} />
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.names)}>
                            Adeyinka Ogunlami
                        </Flex>
                        <Flex flexDirection="column" className={css(styles.title)}>
                            Coordonnateur de programme
                        </Flex>
                    </Box>
                )}
            </SimpleGrid>
        </Box>
        <hr />
        <CoInvestigators />
        <hr />
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                COMITÉ DE PROGRAMME
            </Flex>
            <SimpleGrid columns={2} spacing={10} textAlign="center" marginTop={5} marginBottom={5}>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="Dana.png" alt="Dana Borschewski" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="Dana.png" alt="Dana Borschewski" className={css(styles.images)} />
                    </Flex>
                )}
                        Dana Borschewski
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        VP régional Amériques, Aurrigo
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                       VP régional Amériques, Aurrigo
                    </Flex>
                    )}
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="Burak_PC.jpg" alt="Burak Kantarci" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="Burak_PC.jpg" alt="Burak Kantarci" className={css(styles.images)} />
                    </Flex>
                )}
                        Burak Kantarci
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        Professeur, Directeur du Programme TRAVERSAL, Université d'Ottawa
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Professeur, Directeur du Programme TRAVERSAL, Université d'Ottawa
                    </Flex>
                    )}
                </Box>
               <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="HongwenZhang.jpg" alt="Hongwen Zhang" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="HongwenZhang.jpg" alt="Hongwen Zhang" className={css(styles.images)} />
                    </Flex>
                )}
                        Hongwen Zhang
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        PDG, Wedge Networks
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        PDG, Wedge Networks
                    </Flex>
                    )}
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="UrsulaChallita.jpg" alt="Ursula Challita" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="UrsulaChallita.jpg" alt="Ursula Challita" className={css(styles.images)}/>
                    </Flex>
                )}
                        Ursula Challita
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        Responsable stratégique de l'IA pour les nouveaux secteurs d'activité, Ericsson
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Responsable stratégique de l'IA pour les nouveaux secteurs d'activité, Ericsson
                    </Flex>
                    )}
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="DanMurdock.jpeg" alt="Dan Murdock" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="DanMurdock.jpeg" alt="Dan Murdock" className={css(styles.images)} />
                    </Flex>
                )}
                        Dan Murdock
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        Architecte en chef des transports connectés, Irdeto
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Architecte en chef des transports connectés, Irdeto
                    </Flex>
                    )}
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="paul.jpg" alt="Paul Karam" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="paul.jpg" alt="Paul Karam" className={css(styles.images)} />
                    </Flex>
                )}
                         Paul Karam
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                       Directeur des opérations, Quanser
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Directeur des opérations, Quanser
                    </Flex>
                    )}
                </Box>
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="unknown.png" alt="Kevin Brynes" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="unknown.png" alt="Kevin Brynes" className={css(styles.images)} />
                    </Flex>
                )}
                         Kevin Brynes
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        Cadre de l'industrie pour la sécurité publique et la justice, Microsoft
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Cadre de l'industrie pour la sécurité publique et la justice, Microsoft
                    </Flex>
                    )}
                </Box>
                
                <Box>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    {isMobile ? (
                    <Flex justifyContent="center">
                        <Image src="PoonamLohan.png" alt="Poonam Lohan" className={css(styles.mobileImage)} />
                    </Flex>
                ) : (
                    <Flex justifyContent="center">
                        <Image src="PoonamLohan.png" alt="Poonam Lohan" className={css(styles.images)} />
                    </Flex>
                )}
                        Poonam Lohan
                    </Flex>
                    {isMobile ? (
                    <Flex flexDirection="column" fontSize={16}>
                        Chercheuse postdoctorale associée SCVIC, Université d'Ottawa
                    </Flex>
                    ) : (
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Chercheuse postdoctorale associée SCVIC, Université d'Ottawa
                    </Flex>
                    )}
                </Box>
            </SimpleGrid>
        </Box>
        <hr />
        <Collaborators />
        <hr />
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                PARTENAIRES DE L'INDUSTRIE
            </Flex>
            <IndustryPartners />
        </Box>
        </>
    )
};

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
        marginTop: 100
    },
    parent: {
        maxWidth: '100vw',
        textAlign: 'center'
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
        textAlign: 'center'
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18,
        justifyContent: 'center',
        alignContent: 'center'
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
    },
    images: {
        width: "25%",
        fit: "fit",
        borderRadius: "50%",
    },
    mobileImage: {
        width: "70%",
        borderRadius: "50%"
    }
})

export default ProgramComm