import FooterFr from 'components/FooterFr'
import MobileFooterFr from 'components/MobileFooterFr'
import MobileNavbarFr from 'components/MobileNavbarFr'
import NavbarFr from 'components/NavbarFr'
import HackathonFr from 'components/Program/HackathonFr'
import HowToApplyFr from 'components/Program/HowToApplyFr'
import InternationalExchangesFr from 'components/Program/InternationalExchangesFr'
import InternshipFr from 'components/Program/InternshipFr'
import TrainingFr from 'components/Program/TrainingFr'
import React from 'react';
import { useSelector } from "react-redux";

function ProgramFr() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbarFr />
      <TrainingFr />
      <HackathonFr />
      <InternshipFr />
      <InternationalExchangesFr />
      <HowToApplyFr />
      <MobileFooterFr />
      </>
    ) : (
      <>
      <NavbarFr />
      <TrainingFr />
      <HackathonFr />
      <InternshipFr />
      <InternationalExchangesFr />
      <HowToApplyFr />
      <FooterFr />
      </>
    )}
    </>
  )
}

export default ProgramFr