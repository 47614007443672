import MissionVisionFr from 'components/Home/MissionVisionFr';
import Carousel from '../components/Home/Carousal';
import Carousel2 from 'components/Home/Carousal2';
import NavbarFr from '../components/NavbarFr';
import GoalFr from 'components/Home/GoalFr';
import FooterFr from 'components/FooterFr';
import { useSelector } from "react-redux";
import MobileNavbarFr from 'components/MobileNavbarFr';
import MobileCarousel from 'components/Home/MobileCarousal';
import MobileCarousel2 from 'components/Home/MobileCarousal2';
import MobileFooterFr from 'components/MobileFooterFr';

function HomeFr() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
      <MobileNavbarFr />
      <MobileCarousel />
      <MissionVisionFr />
      <MobileCarousel2 />
      <GoalFr />
      <MobileFooterFr />
      </>
    ) : (
      <>
      <NavbarFr />
      <Carousel />
      <hr />
      <MissionVisionFr />
      <Carousel2 />
      <hr />
      <GoalFr />
      <FooterFr />
      </>
    )}
    </>
  );
}

export default HomeFr;
