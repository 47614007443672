import React from "react";
import NavbarFr from "components/NavbarFr";
import FooterFr from "components/FooterFr";
import ProgramCommFr from "components/AboutUs/ProgramCommFr";
import { useSelector } from "react-redux";
import MobileNavbarFr from "components/MobileNavbarFr";
import MobileFooterFr from "components/MobileFooterFr";

function AboutUsFr () {

    const isMobile = useSelector(state => state.windowSize.isMobile);

    return(
        <>
        {isMobile ? (
            <>
            <MobileNavbarFr />
            <ProgramCommFr />
            <MobileFooterFr />
            </>
        ) : (
            <>
            <NavbarFr />
            <ProgramCommFr />
            <FooterFr />
        </>
        )}
        </>
    )
};

export default AboutUsFr