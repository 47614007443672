import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Program from "./pages/Program";
import Seminars from "pages/Seminars";
import ContactUs from "./pages/ContactUs";
import News from "pages/News";
import Publications from "pages/Publications";

//French Pages:
import HomeFr from 'pages/HomeFr'
import AboutUsFr from "pages/AboutUsFr";
import ProgramFr from "pages/ProgramFr";
import SeminarsFr from "pages/SeminarsFr";
import NewsFr from "pages/NewsFr";
import ContactUsFr from "pages/ContactUsFr";
import PublicationsFr from "pages/PublicationsFr";
// import NotFound from "./pages/NotFound";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/program" element={<Program />} />
        <Route path="/seminars" element={<Seminars />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/publications" element={<Publications />} />
        {/* French Routes: */}
        <Route path="/fr" element={<HomeFr />} />
        <Route path="/about-us-fr" element={<AboutUsFr />} />
        <Route path="/program-fr" element={<ProgramFr />} />
        <Route path="/seminars-fr" element={<SeminarsFr />} />
        <Route path="/news-fr" element={<NewsFr />} />
        <Route path="/contact-us-fr" element={<ContactUsFr />} />
        <Route path="/publications-fr" element={<PublicationsFr />} />
        {/* <Route path="*" element={<NotFound />} /> Catch-all route for 404 */}
      </Routes>
    </Router>
  );
}

export default AppRouter;
