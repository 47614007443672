import React, { useState, useEffect } from "react";
import { Flex, Button, Text, Image, SimpleGrid, Box } from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom"; // Import useLocation and Link
import { StyleSheet, css } from 'aphrodite';

function Navbar () {
    const location = useLocation(); // Get the current location
    const [activeLink, setActiveLink] = useState("/"); // Default to the home page

    useEffect(() => {
        // Update the activeLink based on the current location
        setActiveLink(location.pathname);
    }, [location.pathname]);

    return (
    <>
    <Box className={css(styles.parent)}>
        <Flex>
            <Image src="traversal_logo.png" alt="nserc logo" width='8%' fit="contain" marginLeft={3} mt={2}/>
            <SimpleGrid columns={7} spacing={5} className={css(styles.nav)}> 
                <Link to='/' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/" && styles.activeButton)}
                    >
                        HOME
                    </Button>
                </Link>
                <Link to='/about-us' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/about-us" && styles.activeButton)}
                    >
                        ABOUT US
                    </Button>
                </Link>
                <Link to='/program' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/program" && styles.activeButton)}
                    >
                        PROGRAM
                    </Button>
                </Link>
                <Link to='/seminars' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/seminars" && styles.activeButton)}
                    >
                        SEMINARS
                    </Button>
                </Link>
                <Link to='/publications' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/publications" && styles.activeButton)}
                    >
                        PUBLICATIONS
                    </Button>
                </Link>
                <Link to='/news' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/news" && styles.activeButton)}
                    >
                        NEWS
                    </Button>
                </Link>
                <Link to='/contact-us' className={css(styles.link)}>
                    <Button
                        variant='link'
                        className={css(styles.button, activeLink === "/contact-us" && styles.activeButton)}
                    >
                        CONTACT US
                    </Button>
                </Link>
            </SimpleGrid>
            <Button
            variant='solid'
            size="x-sm"
            className={css(styles.eng)}
            >
            <Link to='/fr'>
                <Text fontSize={14} padding={1} color='white' fontWeight='bold' mr={2} ml={2}>
                EN/FR
                </Text>
            </Link>
            </Button>
        </Flex>
        <Flex className={css(styles.slidingTab)}>
            <Flex fontWeight='bold' fontSize={16}>
            {/* className={css(styles.content)} */}
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            {/* &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL) */}
            </Flex>
        </Flex>
    </Box>
    </>
    );
}

const styles = StyleSheet.create({
    parent: {
      width: '100vw',
      backgroundColor: ' #e2e1dc  ' ,
      position: 'fixed',
      top: 0,
      zIndex: 10,
    //   boxShadow: '2px 3px #D3D3D3',
    },
    nav: {
        width: '100vw',
        padding: '1%',
        justifyContent: 'center',
        textAlign: 'center',
    },
    link: {
        textDecoration: 'none',
        marginRight: '3%',
        marginLeft: '3%',
    },
    button: {
        color: '#315369',
        marginRight: '4%',
        marginLeft: '5%',
        fontWeight: 'bold',
        fontSize: 18,
        ":hover": {
            color: '#DC242C'
        }
    },
    activeButton: {
        // color: ' #080a5a ',
        color: '#DC242C', // Define the style for the active link
    },
    eng: {
        marginRight: 5,
        // backgroundColor: '#DC242C',
        backgroundColor: '#315369',
        paddingLeft: 5,
        paddingRight: 5,
        ":hover": {
            backgroundColor: '#E43C44'
        },
        marginTop: 5,
        marginBottom: 5
        // height: '100%'
    },
    slidingTab: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        justifyContent: 'center'
      },
      
    content: {
        animationName: {
                '0%': {
                    transform: 'translateX(100%)',
                },
                '100%': {
                    transform: 'translateX(-100%)',
                },
            },
            animationDuration: '15s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            color: 'black', // Set text color to white or your preferred color
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            left: 0,
            width: '100%', // Ensure the content takes up the full width
    },
});

export default Navbar;
